<template>
  <div id="FullLayer_1" class="full_layer_popup" :class="{ on : $_Store.layer_popup_data.FullLayer_1.visible }">
    <div class="bg"></div>
    <div class="popup">
      <div class="head">
        <button type="button" class="close_btn" @click="closePopup"></button>
      </div>
      <div class="body">
        <div class="main_title">개인정보처리방침</div>
        <section>
          ‘UX+ ENM’(이하 ‘회사’)는 고객님의 개인 정보를 중요시하며,
          “정보통신망 이용 촉진 및 정보보호”에 관한 법률을 준수하고 있습니다.
          회사는 개인정보취급방침을 통하여 고객님께서 제공하시는 개인 정보가 어떠한 용도와 방식으로 이용되고 있으며, 개인 정보보호를 위해
          어떠한 조치가 취해지고 있는지 알려드립니다.
          회사는 개인정보취급방침을 개정하는 경우 웹사이트 공지사항 (또는 개별 공지)을 통하여 공지할 것입니다.
        </section>
        <section>
          <div class="tit_0">[수집하는 개인 정보 항목]</div>
          회사는 원활한 고객상담 서비스의 제공을 위해 아래와 같은 개인 정보를 수집하고 있습니다.
          수집항목: 이름, 이메일, 휴대폰 번호, 성별, 생년월일, 키, 체중,
          웹사이트/SNS
        </section>
        <section>
          <div class="tit_0">[개인 정보의 수집 및 이용목적]</div>
          가. 개인식별 및 상담 자료
        </section>
        <section>
          <div class="tit_0">[개인 정보의 보유 및 이용 기간]</div>
          이용자의 개인 정보는 원칙적으로, 개인 정보 수집 및 이용목적이
          달성되면 지체 없이 파기합니다.
        </section>
      </div>
      <!--<div class="foot">foot</div>-->
    </div>
  </div>
</template>
<script>
export default {
  name : "FullLayer_1",
  data() {
    return {
      message : "FullLayer_1 Hello Vue",
    };
  },
  methods : {
    closePopup() {
      // console.log("closePopup");
      this.$_Store.layer_popup_data.FullLayer_1.visible = false;
    },
  },
}
</script>