export default [
  {
    path : "/",
    redirect : "/main",// 리다이렉트 필요시 작성
  },
  {
    path : "/main",
    name : "메인",
    component : () => import("@/pages/PageMain.vue"),
    children : [
      {
        path : ":id",
        name : "메인/id",
        component : () => import("@/pages/PageMain.vue"),
      }
    ]
  },
  {
    path : "/test",
    name : "테스트",
    component : () => import("@/pages/PageTest.vue"),
    // meta : {
    //   layout : "LayoutEmpty",
    // }
  },
  {
    path : "/test2",
    name : "테스트2",
    component : () => import("@/pages/PageTest2.vue"),
    // meta : {
    //   layout : "LayoutEmpty",
    // }
  },
  {
    path : "/test3",
    name : "테스트3",
    component : () => import("@/pages/PageTest3.vue"),
    // meta : {
    //   layout : "LayoutEmpty",
    // }
  },
  {
    path : "/404",
    name : "/404",
    component : () => import("@/pages/PageNotFound.vue"),
    meta : {
      // layout : "LayoutEmpty",
    }
  },
  {
    path : "/:catchAll(.*)",
    redirect : '/404',
  }
];