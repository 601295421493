<template>
  <div id="page" class="LayoutDefault" :data-name="$_Store.page_name"
       :class="{ hide_header: $_Store.hide_header, fill_header:$_Store.fill_header }">
    <HeaderBasic>
      <AsideBasic></AsideBasic>
    </HeaderBasic>
    <main>
      <slot></slot>
    </main>
    <FooterBasic></FooterBasic>
  </div>
  <LayerPopupContainer></LayerPopupContainer>
  <GoToCareer></GoToCareer>
</template>
<script>
import HeaderBasic from "@/layouts/parts/HeaderBasic";
import AsideBasic from "@/layouts/parts/AsideBasic";
import FooterBasic from "@/layouts/parts/FooterBasic";
import LayerPopupContainer from "@/layouts/parts/LayerPopupContainer";
import GoToCareer from "@/layouts/parts/GoToCareer";

let beforeScrollY = 0;
export default {
  name : "LayoutDefault",
  components : {GoToCareer, AsideBasic, HeaderBasic, FooterBasic, LayerPopupContainer},
  data() {
    return {
      message : "LayoutDefault HI",
    };
  },
  mounted() {
    this.$nextTick(() => {
      // 전체 화면내용이 렌더링된 후에 아래의 코드가 실행됩니다.
      // console.log("App mounted");
      
      window.addEventListener("scroll", this.onWindowScroll);
      this.onWindowScroll();
      
      
    })
  },
  beforeUnmount() {
    // 컴포넌트 인스턴스가 마운트 해제(unmounted) 되기 직전에 호출됩니다. 이 단계에서 인스턴스는 여전히 완전하게 작동합니다.
    window.removeEventListener("scroll", this.onWindowScroll);
  },
  methods : {
    changeHeaderColor() {
      // const header = document.querySelector('header');
      //const visual = document.querySelector('.visual_sec');
      const hei = window.outerHeight;//visual.offsetHeight;
      // console.log("hei : " + hei);
      if (hei < window.scrollY) {
        // 헤더 색 채우기
        this.$_Store.fill_header = true;
      } else {
        // 헤더 색 비우기
        this.$_Store.fill_header = false;
      }
    },
    
    onWindowScroll() {
      const header = document.querySelector('header');
      const aside = document.querySelector('#aside');
      const hei = header.offsetHeight;
      if (beforeScrollY < window.scrollY && hei < window.scrollY && !aside.classList.contains('active')) {
        this.$_Store.hide_header = true;
      } else {
        this.$_Store.hide_header = false;
      }
      beforeScrollY = window.scrollY;
      
      this.changeHeaderColor();
    },
  },
};
</script>
<style lang="scss" scoped>
//#page{ background-color:gold; }
</style>