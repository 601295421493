<template>
  <aside id="aside" :class="{ active: $_Store.active_aside }">
    <div class="inner">
      <nav>
        <router-link to="/about">
          ABOUT
        </router-link>
        <router-link to="/creator">
          CREATOR
        </router-link>
        <router-link to="/qna">
          Q&A
        </router-link>
        <router-link to="/news">
          NEWS
        </router-link>
        <router-link to="/career">
          CAREER
        </router-link>
      </nav>
      <div class="address">
        <div class="head">(주)UX+ ENTERTAINMENT</div>
        <div class="body">
          서울특별시 서초구 서초대로 46길 19-18<br>
          (서초동, 성진빌딩)
        </div>
      </div>
    </div>
  </aside>
</template>
<script>
export default {
  name : "AsideBasic",
  data() {
    return {
      message : "Hello Vue",
    };
  },
}
</script>
