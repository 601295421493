import '@/assets/scss/global.scss';
import '@/assets/js/global';
import {createApp} from 'vue';
import App from './App.vue';
import router from '@/routes/index';
import {createPinia} from 'pinia';
import {useCommonStore} from "@/stores/common";

const app = createApp(App);
app.use(router);
app.use(createPinia());
app.config.globalProperties.$_Store = useCommonStore(); // 전역 설정
app.mount('#app');
app._container.dataset.env = process.env.NODE_ENV;

let is_mobile = window.DEVICE_CHECK();

if ( (process.env.NODE_ENV === "production" || process.env.NODE_ENV === "stage") && !is_mobile ){
  window.location.href = window.location.href.replace('m.', 'www.');
}

if (process.env.NODE_ENV === "production" || process.env.NODE_ENV === "stage"){
  
  const urlStr = window.location.href;
  const url = new URL(urlStr);
  const urlParams  = url.searchParams;
  const cookie_key = 'n';
  let checkEvent = urlParams.get(cookie_key);
  app.config.globalProperties.$_Store.codename = checkEvent;
  // console.log('checkEvent : ' + checkEvent);
  
  setCookie(cookie_key,checkEvent,"30") //변수, 변수값, 저장기간(1은 Day 하루 동안을 의미합니다.)

}

// 쿠키 생성 함수
function setCookie(cName, cValue, cDay){
  var expire = new Date();
  expire.setDate(expire.getDate() + Number(cDay));
  var cookies = cName + '=' + escape(cValue) + '; path=/ '; // 한글 깨짐을 막기위해 escape(cValue)를 합니다.
  if(typeof cDay != 'undefined') cookies += ';expires=' + expire.toUTCString() + ';';
  document.cookie = cookies;
}


