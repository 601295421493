import {useCommonStore} from "@/stores/common";

/**
 * 클래스 삽입
 * @param el
 * @param class_name
 * Util.addClass(element, "focused");
 */
const addClass = (el, class_name) => {
  if (!el) return;
  el.classList.add(class_name);
}

/**
 * 클래스 제거
 * @param el
 * @param class_name
 * Util.removeClass(element, "focused");
 */
const removeClass = (el, class_name) => {
  if (!el) return;
  el.classList.remove(class_name);
}

/**
 *
 * @param el
 * @param class_name
 * @returns {boolean}
 */
const hasClass = (el, class_name) => {
  if (!el) return;
  return el.classList.contains(class_name);
}

/**
 * 자신 이외 클릭 감지
 * @param el
 * @param callback
 */
const detectOutsideClick = (el, callback) => {
  const target = event.target;
  console.log("target : ", target);
  if (el !== target && !el.contains(target)) {
    if (callback) callback();
  }
}

/**
 * 커스텀 얼럿 띄우기
 * @param message
 * @param callback
 */
const alert = (message, callback) => {
  const store = useCommonStore();
  store.layer_popup_data.CustomAlert.text = message;
  store.layer_popup_data.CustomAlert.visible = true;
  if (callback) store.layer_popup_data.CustomAlert.callback = callback;
}

export default {
  addClass,
  removeClass,
  hasClass,
  detectOutsideClick,
  alert,
}