<template>
  <div id="LayerPopup_1" class="layer_popup" :class="{ on : $_Store.layer_popup_data.LayerPopup_1.visible }">
    <div class="bg" @click="closePop"></div>
    <div class="popup" style="width:356px;">
      <!--<div class="head">HEAD</div>-->
      <div class="body">
        <div class="support_completed">
          <article class="atc_0">
            접수가 <span class="color">완료</span>되었습니다.
          </article>
          <article class="atc_1">
            <!--유엑스플러스 엔터테인먼트에 지원해 주셔서 감사합니다.<br>-->
            <!--접수 완료된 지원서는 수정 및 삭제가 불가능하며,<br>-->
            <!--지원서 검토 후 빠른 시일 내에 연락드리겠습니다.-->
          </article>
        </div>
      </div>
      <div class="foot">
        <button class="cst_btn block" theme="A" @click="closePop">확인</button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name : "LayerPopup_1",
  data() {
    return {
      message : "Hello Vue",
    };
  },
  methods : {
    closePop() {
      this.$_Store.layer_popup_data.LayerPopup_1.visible = false;
      history.back();
    },
  },
}
</script>
<style lang="scss" scoped>
.layer_popup.on{
  > .popup{
    opacity:0;
    animation:0.3s cubic-bezier(0.215, 0.610, 0.355, 1.000) 0.0s 1 normal forwards running fadein_scale;
  }
}

@keyframes fadein_scale{
  from{
    opacity:0;
    transform:scale(0.7);
  }
  to{
    opacity:1;
    transform:scale(1);
  }
}

.support_completed{
  background-color:$N_COLOR_2; text-align:center;
  
  > article.atc_0{
    padding-top:112px;
    font-weight:600; font-size:26px; line-height:36px; letter-spacing:-0.5px;
    background:url("@/assets/svg_icon/complete.svg") no-repeat center 48px; background-size:48px 30px;
  }
  
  > article.atc_1{
    padding-left:31px; padding-right:31px; padding-top:14px; padding-bottom:40px;
    font-weight:500; font-size:14px; line-height:24px; letter-spacing:-0.5px;
    color:$N_COLOR_7;
  }
  
  > article.atc_2{
  
  }
}
</style>